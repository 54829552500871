.mbsc-calendar-cell-inner {
    overflow-x: hidden;
    overflow-y: auto;
}

.mbsc-calendar-labels {
    display: flex;
    flex-flow: column;
    row-gap: 0.6em;
}
